.TechnoTiles {
    width: 100vw;
    height: 125px;
    background-image: url('../../assets/tileline.png');
    background-repeat: repeat-x;
    background-size: 2640px auto; /* Taille de l'image en pixels */
    background-position: 0 center;
    animation: scrollInfinite linear infinite;
    animation-duration: 15s; /* Ajuste la durée selon la vitesse souhaitée */
  }
  
  @keyframes scrollInfinite {
    0% {
      background-position: 0 center;
    }
    100% {
      background-position: 2640px center; /* Fait défiler l'image sur toute sa largeur */
    }
  }
  