.navbar-container {
    display: inline-flex; /* Change de flex à inline-flex pour que le conteneur ne prenne que l'espace nécessaire */
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 15px;
    z-index: 1000;
    font-size: clamp(6px, 1.25vw, 10px);
    gap: clamp(0px, 1vw, 17px);
    color: #D4F0FA;
    backdrop-filter: blur(4px);
    padding: 0px clamp(12px, 2vw, 16px); /* Ajoute du padding pour rendre le conteneur plus esthétique */
    left: 50%; /* Place la navbar au milieu horizontalement */
    transform: translateX(-50%); /* Centre la navbar en utilisant une transformation */
    background-color: #03062C88;
    border-radius: 50px;
}


.navbar-button {
    cursor: pointer;
    color: #D4F0FA;
    font-weight: 600;
    transition: color 0.15s ease, transform 0.15s ease, background-color 0.15s ease;
    padding-inline: clamp(10px, 1.25vw, 15px);
    padding-block: clamp(5px, 1.25vw, 10px);
    border-radius: 50px;

}

.navbar-button.active {
    color: #03062C;
    background-color: #FFDC67;
    transform: scale(1.0); /* Taille normale pour l'élément actif */
}

.navbar-button:hover {
    color: #FFDC67;
    transform: scale(1.08); /* Mise à l'échelle lors du survol */
}

.navbar-button.active:hover {
    color: #03062C; /* Maintient la couleur actuelle lors du survol d'un élément actif */
    transform: scale(1.08); /* Légère mise à l'échelle lors du survol */
}
