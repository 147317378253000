.project-card {
    width: clamp(200px, 75vw, 380px);
    height: clamp(400px, 100vw, 480px);;
    background-color: #03062C88; 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    transition: transform 0.15s ease;
    overflow: hidden;
    backdrop-filter: blur(4px);
}

.project-card:hover {
    transform: scale(1.05);
}

.image-projet {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.image-projet img {
    height: 230px;
    width: auto; /* Préserve les proportions */
    cursor: pointer;
}

/* Optionnel si tu veux également appliquer aux vidéos */
.image-projet video {
    height: 230px;
    object-fit: cover;
    cursor: pointer;
}

.texte-projet {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    gap: clamp(0px, 1vw, 8px);
    width: max-content;
}

.date-projet h1, 
.titre-projet h1, 
.description-projet h1 {
    color: #D4F0FA;
    margin: 5px 0;
    text-align: center;
}

.date-projet h1 {
    font-size: 18px;
    font-weight: 200;
    height: 5px;
}

.titre-projet h1 {
    font-size: clamp(21px,1.5vw,24px);
    font-weight: 600;
    height: 30px;
}

.description-projet {
    width: 100%;
}

.description-projet h1 {
    color: #8FA9CA;
    font-size: clamp(12px,2.5vw,18px);
    font-weight: 300;
    text-align: justify;
    margin: 0 0;
    height: 30px;
}

.description-projet h1::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0;
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8FA9CA;
    font-size: 20px;
}

.techno-projet {
    padding-bottom: 5px;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.techno-projet .icon:hover {
    transform: scale(1.15);
    filter: saturate(2);
}

.techno-projet .icon {
    width: clamp(30px,6vw,40px);
    height: auto;
    transition: transform 0.15s ease, fill 0.15s ease;
}

.techno-projet .icon path {
    transition: fill 0.15s ease;
}
