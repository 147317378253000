.main-page {
  color: #D4F0FA;
}

.home-container {
  position: relative; /* Nécessaire pour que les enfants puissent se positionner */
  width: 100%;
  min-height: 100vh; /* Prend toute la hauteur de la fenêtre */
  z-index: 1;
  background-color: #03062C;
  background-image: url('assets/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Permet d'éloigner les éléments entre eux */
}

.down-arrow {
  position: absolute; /* Permet de positionner précisément l'élément */
  bottom: 50px; /* Distance depuis le bas de la page */
  left: 50%; /* Centre horizontalement */
  transform: translateX(-50%); /* Centre l'élément en ajustant son déplacement */
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}


.down-arrow svg {
  width: 100%;
  height: 100%;
  transition: transform 0.15s ease, fill 0.15s ease;
}

.down-arrow svg:hover {
  transform: scale(1.1);
}

.down-arrow path {
  transition: fill 0.15s ease;
}

.down-arrow svg:hover path {
  fill: #FFDC67;
}

.experiences-container{
  background-color: #00113C;
  background-image: linear-gradient(#1b2a7c 1px, transparent 1px), 
                    linear-gradient(90deg, #1b2a7c 1px, transparent 1px);
  background-size: 50px 50px; /* Ajustez la taille de la grille ici */
  background-position: 0 0;
  background-attachment: fixed; /* Garde la grille fixe lors du scroll */
  width: 100%;
  padding-bottom: 50px;
  height:1300px;
}

.projects-container {
  height: 1350px;
  background: radial-gradient(circle, #01407B 0%, #001B55 50%, #070027 100%);
  position: relative; /* Nécessaire pour le positionnement des couches parallaxe */
  overflow: hidden; /* Assure que les couches parallaxe ne débordent pas */
}

.projects-list {
  display: grid;
  justify-items: center;
  justify-content: center; /* Centre les cartes au centre horizontalement */
  grid-template-columns: repeat(3, 380px); /* 3 colonnes de largeur égale */
  gap: 30px; /* Espace entre les divs enfants */
  position: relative;
  padding-top: 40px;
  padding-bottom: 100px;
}

.competences-container{
  top:500px;
  min-height: 100vh;
  background-image: url('assets/tilebackground.png');
  background-color: #081B49;
  background-repeat: repeat-x;
  animation: scrollBackground linear infinite;
  animation-duration: 30s; /* Ajuste la durée selon la vitesse souhaitée */
}

@keyframes scrollBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -2640px center; /* Fait défiler l'image sur toute sa largeur */
  }
}


.contact-footer{
  height:200px;
  background-color: #03062C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #D4F0FA;
  background-image: url("./assets/dot.png"); /* Ajout de l'image en arrière-plan */
  background-position: start;
  background-repeat: repeat;
  background-size: 20px; /* Ajuster la taille de l'image */
}

.contact-footer h1{
  font-size: clamp(15px,1vw,18px);
  font-weight: 400;
  color: #8FA9CA;
  margin: 0;
}

.titres-section{
  z-index: 5;
}

.navbargap{
  height: clamp(70px,15vw, 110px);
}

@media (max-width: 1250px) {
  .experiences-container{
      height: 1750px; 
  }
  .projects-container {
    height: 1850px;
  }
  .projects-list {
    grid-template-columns: repeat(2, 380px); /* 3 colonnes de largeur égale */
    justify-content: center;
    justify-items: center;
  }
  
}

@media (max-width: 950px) {
  .projects-container {
    height: auto;
  }
  .projects-list {
    grid-template-columns: repeat(1, 380px); /* 3 colonnes de largeur égale */
    justify-content: center;
    justify-items: center;
  }
  
}

@media (max-width: 800px) {
  .experiences-container{
    height:1850px;
  }
}

@media (max-width: 800px) {
  .experiences-container{
    height:auto;
  }
}
