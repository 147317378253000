.TextInfos > * {
    margin-bottom: clamp(25px,2.5vw,60px); /* Ajoute une marge sous chaque élément enfant de TextInfos */
}

.HomeInfos {
    position: absolute; /* Assure que HomeInfos est superposé au-dessus de Canvas */
    z-index: 2; /* Assure que HomeInfos est au-dessus de Canvas */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Prend toute la hauteur de son parent (home-container) */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.profile-img {
    width: clamp(225px,25vw,400px);
    height: auto;
    border-radius: 50%;
}


.Split {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center; 
  gap: 80px;
}

.salut{
    color: #8FA9CA;
    font-size: clamp(15px,1.5vw,25px);
    font-weight: 500;
    position: relative;
    top: 1vw; /* Déplace l'élément de 100px vers le bas */
}

.clement{
    color: #D4F0FA;
    font-size: clamp(35px,4vw,60px);
    font-weight: 800;
    top: 15px;
}

.developpeur{
    color: #FFDC67;
    font-size: clamp(25px,2.5vw,40px);
    font-weight: 700;
    line-height: 20px;
}

.construis h1 {
    color: #8FA9CA;
    font-size: clamp(17.5px,2vw,30px);
    line-height: 15px;
    font-weight: 400;
}


.precision {
    background: linear-gradient(to right, #FFDC67, #F532CD);
    -webkit-text-fill-color: transparent; /* Utilisé pour les navigateurs WebKit */
    background-clip: text; /* Pour la compatibilité */
    font-weight: 600;
}

.passion {
    background: linear-gradient(to right, #FF4FFC, #00FFCC);
    -webkit-text-fill-color: transparent; /* Utilisé pour les navigateurs WebKit */
    background-clip: text; /* Pour la compatibilité */
    font-weight: 600;
}

.SocialIcons {
    display: flex; 
    gap: 30px; 
}

.SocialIcons .icon {
    cursor: pointer;
    height: auto;
}

.SocialIcons .icon:hover {
    transform: scale(1.15);
}

.SocialIcons .icon {
    width: 40px;
    height: auto;
    cursor: pointer;
    transition: transform 0.15s ease, fill 0.15s ease;
}

.SocialIcons .icon path {
    transition: fill 0.15s ease;
}

.SocialIcons .icon:hover path {
    fill: #FFDC67;
}



@media (max-width: 800px) {

    .ProfilePicture {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Split {
        display: block;
    }

    .SocialIcons {
        justify-content: center; /* Centre les éléments horizontalement */
        align-items: center; /* Centre les éléments verticalement */
    }
    
}