/* Styling for the competences list container */
.competences-list-row {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Centre les cartes au centre horizontalement */
    gap: 30px; /* Espace entre les divs enfants */
    position: relative;
    padding: 80px;
    align-items: start; /* Les boîtes s'aligneront selon leur propre contenu */
}

.competences-list-colomn {
    display: grid;
    justify-content: center; /* Centre les cartes au centre horizontalement */
    gap: 30px; /* Espace entre les divs enfants */
    position: relative;
    align-items: start; /* Les boîtes s'aligneront selon leur propre contenu */
}

/* Competence box styling */
.competence-box {
    width: clamp(200px,70vw,500px);;
    backdrop-filter: blur(4px);
    background-color: #03062C88;
    padding: 25px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
}

.competence-box.certifications {
    margin-top: 75px;
}


.competence-box h1 {
    font-size: clamp(16px, 4vw, 30px);
    font-weight: 600;
    color: #D4F0FA;
    margin: 0;
    margin-bottom: 25px;
}

.certification-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-radius: 50px;
    width: fit-content;
    margin-bottom: 20px;
}


.certification-content {
    background-color: #D4F0FA;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: clamp(5px,1vw,10px);
    height: clamp(25px,7vw,50px);
    width: clamp(175px,60vw,400px); /* Ajout de l'unité 'px' pour la largeur */
    padding-left: clamp(5px,2vw,15px);
    padding-right: clamp(5px,2vw,15px);
    overflow: hidden; /* Empêche le texte de dépasser la boîte */
    white-space: nowrap; /* Empêche le texte de passer à la ligne */
    text-overflow: ellipsis; /* Ajoute '...' si le texte est trop long */
    transition: transform 0.15s ease, background-color 0.1s ease;
    cursor: pointer;
}

.certification-content:hover {
    transform: scale(1.02); /* Slight enlargement */
    background-color: #bde6f5; /* Change background color on hover */
    filter: saturate(1.2); /* Slightly more saturation */
}

.cert-icon {
    display: flex;
    padding: 5px;
    width: auto;
    height: clamp(20px,5vw,35px);
    transition: transform 0.15s ease, fill 0.15s ease;
}

/* Hover effect */
.cert-icon:hover {
    transform: scale(1.15);
    filter: saturate(2);
}

/* Path inside icon changes on hover */
.cert-icon path {
    transition: fill 0.15s ease;
}


.certification-text{
    height: 50px;
    justify-content: center;
    justify-items: center;
    align-items: center;     
    display: flex;
    color: #03062C;
    font-size: clamp(6px,2.2vw,15px);
    font-weight: 700;
    margin: 0;
}

/* Flexbox container for icons */
.techno-competences {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px; /* Limits the width to 500px */
}

/* Icon styles */
.techno-competences .icon {
    width: auto;
    height: clamp(30px,6vw,40px);
    transition: transform 0.15s ease, fill 0.15s ease;
}

/* Hover effect for icons */
.techno-competences .icon:hover {
    transform: scale(1.15);
    filter: saturate(2);
}

/* Path inside icon changes on hover */
.techno-competences .icon path {
    transition: fill 0.15s ease;
}


@media (max-width: 1250px) {
    .competences-list-row{
        display: block; 
    }
    .competence-box.certifications {
        margin-top: 30px;
    }
    
  }
  