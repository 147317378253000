.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    max-width: 1000px;
    margin: 0 auto;
}

.texte-image {
    display: flex;
    width: 100%;
    flex-direction:row-reverse
}

.texte-experience {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.image-entreprise {
    display: flex;
    align-items: center;
    justify-content: center; /* Pour centrer horizontalement l'image aussi si besoin */
    
}

.image-entreprise img {
    max-width: clamp(225px,60vw,380px);
    border-radius: 20px;
    display: block;
    max-height: 100%;
    transition: transform 0.15s ease, background-color 0.1s ease;
    cursor: pointer;
}

.image-entreprise img:hover {
    transform: scale(1.03); /* Slight enlargement */
    filter: saturate(1.3);
}



.date-entreprise h1,
.lieu h1 {
    font-size: clamp(15px,1vw,18px);
    font-weight: 400;
    color: #8FA9CA;
    margin: 0;
}

.poste h1 {
    font-size: clamp(21px,1.5vw,24px);
    font-weight: 600;
    color: #D4F0FA;
    margin: 0;
}

.experience-bulletpoints h1 {
    font-size: clamp(12px,2.5vw,18px);
    font-weight: 400;
    color: #D4F0FA;
    margin: 5px 0;
    line-height: 1.8; 
}

.technos {
    display: flex; /* Une seule colonne qui prend tout l'espace */
    justify-items: center; /* Centre la grille */
    padding-top: 20px; /* Ajoutez du padding si nécessaire */
}

.row {
    display: flex; /* 5 colonnes avec une largeur minimale de 40px */
    gap: 25px; /* Espacement entre les éléments */
    justify-content: center; /* Centre les éléments dans la ligne */
    padding: 12px;
}

.technos .icon:hover {
    transform: scale(1.15);
    filter: saturate(2);
}

.technos .icon {
    width: clamp(30px,6vw,40px);
    height: auto;
    transition: transform 0.15s ease, fill 0.15s ease;
}

.technos .icon path {
    transition: fill 0.15s ease;
}





@media (max-width: 1250px) {
    .texte-image {
        flex-direction: column;
        align-items: center;
        gap:20px
    }

    .texte-experience,
    .image-container {
        flex: none;
    }
}




@media (max-width: 800px) {
    .technos {
        display: grid; /* Une seule colonne qui prend tout l'espace */
    }
}