.section-title {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: end;
    font-size: clamp(30px,3vw,45px);
    font-weight: 600;
    position: relative;
    z-index: 15;
    margin-bottom: 25px;
}